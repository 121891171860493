import { useEffect, useRef, useState } from 'react';
import Janus from '../libs/Janus.js';
import { getDevices } from '../utils/getInitDevices.ts';
import { toast } from 'react-toastify';

const apisecret = 'm0vSIgfUfnOk';

const audioOutput = { deviceId: null };

export function useVideoRoom(roomName) {
  const [joined, setJoined] = useState(false);
  const [destroyed, setDestroyed] = useState(false);
  const [remoteVideos, setRemoteVideos] = useState([]);
  const [videoRoom, setVideoRoom] = useState(null);
  const [audioOutputDevices, setAudioOutputDevices] = useState([]);
  const [audio, setAudio] = useState([]);
  const [video, setVideo] = useState([]);

  const roomNameRef = useRef();
  roomNameRef.current = roomName;

  console.log('rendering useVideoRoom');

  useEffect(() => {
    Janus.init({
      debug: 'all',
      callback: () => {
        const server = 'wss://zingizivideo.ontech.one/ws';

        function addClient(x) {
          console.log('addClient');
          setRemoteVideos((remoteVideos) => {
            console.log('setRemoteVideos');
            const newRV = remoteVideos.filter((y) => y.display !== x.display);
            return [...newRV, x];
          });
        }

        function removeClient(id) {
          setRemoteVideos((remoteVideos) => {
            const v = remoteVideos.find((x) => x.handleId == id);
            if (v) {
              v.remove();
              return remoteVideos.filter((x) => x !== v);
            }
            return remoteVideos;
          });
        }
        setVideoRoom((videoRoom) => {
          console.log('setVideoRoom');
          if (videoRoom == null) {
            console.log('videoRoom == null');
            return new VideoRoom(
              server,
              setJoined,
              addClient,
              removeClient,
              setAudioOutputDevices,
              setAudio,
              setVideo,
              setDestroyed,
              roomNameRef.current
            );
          }
          return videoRoom;
        });
      },
    });
  }, []);

  function setAudioOutputDeviceId(deviceId) {
    audioOutput.deviceId = deviceId;
    const event = new CustomEvent('changeAudioOutput', {
      bubbles: true,
      detail: { deviceId },
      cancelable: true,
      composed: true,
    });
    document.dispatchEvent(event);
  }

  return [
    videoRoom,
    joined,
    remoteVideos,
    audioOutputDevices,
    setAudioOutputDeviceId,
    audio,
    video,
    destroyed,
  ];
}

class VideoRoom {
  constructor(
    server,
    setJoined,
    addClient,
    removeClient,
    setAudioOutputDevices,
    setAudio,
    setVideo,
    setDestroyed,
    roomName
  ) {
    this.opaqueId = `user_${Janus.randomString(12)}`;
    this.roomId = null;
    this.setJoined = setJoined;
    this.addClient = addClient;
    this.removeClient = removeClient;
    this.isLocalDataOpen = false;
    this.isRemoteDataOpen = false;
    this.bitrateMaxValue = 0;
    this.bitrate_cap = true;
    this.pluginHandle = null;
    this.remoteHandles = [];
    this.isMuteAudio = false;
    this.isMuteVideo = false;
    this.dataHandles = [];
    this.setDestroyed = setDestroyed;
    this.videoTrack = null;
    this.audioTrack = null;
    this.roomName = roomName;

    console.warn('this.opaqueId: ', this.opaqueId);

    // для фиксации состояния готовности, чтобы случайно не дестроить заранее, что вызовет баг
    this.janusReadyPromise = new Promise((resolve, reject) => {
      this.janusReadyResolve = resolve;
      this.janusReadyReject = reject;
    });

    this.mediaStream = new MediaStream();
    this.audioTrack = null;
    this.videoTrack = null;

    getDevices().then(({ audio, video, audioOutput }) => {
      setAudio(audio);
      setVideo(video);
      setAudioOutputDevices(audioOutput);
      Promise.all([
        new Promise((resolve) => {
          audio &&
            audio.length > 0 &&
            navigator.mediaDevices
              .getUserMedia({ audio: true })
              .then((stream) => {
                this.audioTrack = stream.getAudioTracks()?.[0];

                stream.getAudioTracks().forEach((track, index) => {
                  index > 0 && track.stop();
                });

                if (this.audioTrack) {
                  this.mediaStream.addTrack(this.audioTrack);
                  console.warn('___init this.audioTrack', this.audioTrack);
                }
              })
              .catch(() => {
                toast.error('Разрешите доступ к  микрофону');
                console.warn('getUserMedia audio track');
              })
              .finally(resolve);
        }),
        new Promise((resolve) => {
          video &&
            video.length > 0 &&
            navigator.mediaDevices
              .getUserMedia({ video: true })
              .then((stream) => {
                this.videoTrack = stream.getVideoTracks()?.[0];

                stream.getVideoTracks().forEach((track, index) => {
                  index > 0 && track.stop();
                });

                if (this.videoTrack) {
                  this.mediaStream.addTrack(this.videoTrack);
                  console.warn('___init this.videoTrack', this.videoTrack);
                }
              })
              .catch(() => {
                toast.error('Разрешите доступ к камере');
                console.warn('getUserMedia audio track');
              })
              .finally(resolve);
        }),
      ]).then(() => {
        const video = document.getElementById('localvideo');
        Janus.attachMediaStream(video, this.mediaStream);
      });
    });

    this.janus = new Janus({
      server,
      apisecret,
      error: (cause) => {
        Janus.error('Janus session initialization error:', cause);
        this.janusReadyReject(cause);
      },
      destroyed: () => {
        Janus.log('Janus session was destroyed!');
      },
      success: () => {
        this.janusReadyResolve();
        this.attachVideoRoomPlugin(this.opaqueId);
      },
    });
  }

  toggleAudio = (deviceId) => {
    this.isMuteAudio = !this.isMuteAudio;
    const audio = !this.isMuteAudio;

    if (audio === false) {
      if (this.audioTrack) {
        this.audioTrack.stop();
      }
    } else {
      navigator.mediaDevices.getUserMedia({ audio: { deviceId } }).then((stream) => {
        this.audioTrack = stream.getAudioTracks()?.[0];

        const newStream = new MediaStream();

        stream.getAudioTracks().forEach((track, index) => {
          index > 0 && track.stop();
        });

        const media = { data: true };

        if (this.audioTrack) {
          newStream.addTrack(this.audioTrack);

          if (this.mediaStream.getAudioTracks().length > 0) {
            media.replaceAudio = true;
          } else {
            media.addAudio = true;
          }
        }

        if (this.mypvtid) {
          this.pluginHandle.createOffer({
            media,
            stream: newStream,
          });
        } else {
          if (this.videoTrack) {
            newStream.addTrack(this.videoTrack);
          }

          this.mediaStream = newStream;
          const video = document.getElementById('localvideo');
          video.pause();
          Janus.attachMediaStream(video, this.mediaStream);
          video.play();
        }
      });
    }
    return audio;
  };

  toggleVideo = (deviceId) => {
    this.isMuteVideo = !this.isMuteVideo;
    const video = !this.isMuteVideo;

    if (video === false) {
      if (this.videoTrack) {
        this.videoTrack.stop();
        console.warn('this.videoTrack.stop');
      }
    } else {
      navigator.mediaDevices.getUserMedia({ video: { deviceId } }).then((stream) => {
        this.videoTrack = stream.getVideoTracks()?.[0];

        const newStream = new MediaStream();

        stream.getVideoTracks().forEach((track, index) => {
          index > 0 && track.stop();
        });

        const media = { data: true };

        if (this.videoTrack) {
          newStream.addTrack(this.videoTrack);

          if (this.mediaStream.getVideoTracks().length > 0) {
            console.warn('media.replaceVideo');
            media.replaceVideo = true;
          } else {
            console.warn('media.addVideo');
            media.addVideo = true;
            media.replaceVideo = true;
          }
        }

        if (this.audioTrack) {
          newStream.addTrack(this.audioTrack);
          media.replaceVideo = true;
        }

        if (this.mypvtid) {
          this.pluginHandle.send({
            message: { request: 'configure', video: true },
          });

          this.pluginHandle.createOffer({
            media,
            stream: newStream,
            success: (jsep) => {
              console.log('this.pluginHandle.createOffer');
            },
          });
        } else {
          if (this.audioTrack) {
            newStream.addTrack(this.audioTrack);
          }

          this.mediaStream = newStream;
          const video = document.getElementById('localvideo');

          Janus.attachMediaStream(video, this.mediaStream);
        }
      });
    }
    return video;
  };

  changeAudioDevice = async (deviceId) => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId } });
    this.audioTrack = stream.getAudioTracks()?.[0];

    const newStream = new MediaStream();

    stream.getAudioTracks().forEach((track, index) => {
      index > 0 && track.stop();
    });

    const media = { data: true };

    if (this.audioTrack) {
      newStream.addTrack(this.audioTrack);

      if (this.mediaStream.getAudioTracks().length > 0) {
        media.replaceAudio = true;
      } else {
        media.addAudio = true;
      }
    }

    if (this.mypvtid) {
      this.pluginHandle.send({
        message: { request: 'configure', video: true },
      });

      this.pluginHandle.createOffer({
        media,
        stream: newStream,
        success: (jsep) => {
          console.log('this.pluginHandle.createOffer');
        },
      });
    } else {
      if (this.videoTrack) {
        newStream.addTrack(this.videoTrack);
      }

      this.mediaStream = newStream;
      const video = document.getElementById('localvideo');

      console.warn(this.mediaStream.getTracks());

      Janus.attachMediaStream(video, this.mediaStream);
    }
  };

  changeVideoDevice = (deviceId) => {
    navigator.mediaDevices.getUserMedia({ video: { deviceId } }).then((stream) => {
      this.videoTrack = stream.getVideoTracks()?.[0];

      const newStream = new MediaStream();

      stream.getVideoTracks().forEach((track, index) => {
        index > 0 && track.stop();
      });

      const media = { data: true };

      if (this.videoTrack) {
        newStream.addTrack(this.videoTrack);

        if (this.mediaStream.getVideoTracks().length > 0) {
          console.warn('media.replaceVideo');
          media.replaceVideo = true;
        } else {
          console.warn('media.addVideo');
          media.addVideo = true;
          media.replaceVideo = true;
        }
      }

      if (this.audioTrack) {
        newStream.addTrack(this.audioTrack);
        media.replaceVideo = true;
      }

      if (this.mypvtid) {
        this.pluginHandle.send({
          message: { request: 'configure', video: true },
        });

        this.pluginHandle.createOffer({
          media,
          stream: newStream,
          success: (jsep) => {
            console.log('this.pluginHandle.createOffer');
          },
        });
      } else {
        if (this.audioTrack) {
          newStream.addTrack(this.audioTrack);
        }

        this.mediaStream = newStream;
        const video = document.getElementById('localvideo');

        console.warn(this.mediaStream.getTracks());

        Janus.attachMediaStream(video, this.mediaStream);
      }
    });
  };

  attachVideoRoomPlugin = () => {
    this.janus.attach({
      plugin: 'janus.plugin.videoroom',
      opaqueId: this.opaqueId,
      error: (cause) => {
        Janus.error('Unable to attach to plugin: ', cause);
      },
      oncleanup: () => {
        Janus.log('Peer connection with plugin closed!');
      },
      detached: () => {
        Janus.log('Janus session destroyed!');
        console.warn('Janus session destroyed!');
      },
      onmessage: (msg, jsep) => {
        const event = msg.videoroom;

        console.warn('onmessage', msg, jsep);

        if (event) {
          if (event === 'joined') {
            this.mypvtid = msg.private_id;

            this.startPublishing();

            this.pluginHandle.data({ text: '--- ' + this.opaqueId });

            msg.publishers && this.remoteFeedHandler(msg.publishers);
          } else if (event === 'destroyed') {
            Janus.warn('The room has been destroyed!');
            this.destroyRoom();
          } else if (event === 'event') {
            if (msg.publishers !== undefined && msg.publishers !== null) {
              console.warn('publishers', msg.publishers);
              msg.publishers && this.remoteFeedHandler(msg.publishers);
            } else if (msg.leaving != null) {
              this.removeClient(msg.leaving);

              const remoteHandle = this.remoteHandles.find((x) => x.rfid === msg.leaving);

              if (remoteHandle) {
                this.remoteHandles = this.remoteHandles.filter((x) => x !== remoteHandle);
                remoteHandle.detach();
              }
            } else if (msg.unpublished != null) {
              if (msg.unpublished === 'ok') {
                this.pluginHandle.hangup();
                return;
              }

              const remoteHandle = this.remoteHandles.find((x) => x.rfid === msg.unpublished);

              if (remoteHandle) {
                this.remoteHandles = this.remoteHandles.filter((x) => x !== remoteHandle);
                remoteHandle.detach();
              }
            }
          }
        }

        if (jsep) {
          this.pluginHandle.handleRemoteJsep({ jsep });
        }
      },
      onlocalstream: (stream) => {
        const video = document.getElementById('localvideo');
        console.warn(stream);
        Janus.attachMediaStream(video, stream);
      },
      success: (pluginHandle) => {
        this.pluginHandle = pluginHandle;
      },
      ondataopen: (data) => {
        Janus.log('The DataChannel is available!');
      },
      ondata: (data) => {
        Janus.debug('We got data from the DataChannel!', data);
      },
    });
  };

  joinRoom = async (roomName, userName) => {
    const msgList = { request: 'list' };

    let list;
    try {
      const response = await this.sendMessage({ message: msgList });
      console.log(response);
      list = response?.list;
    } catch (error) {
      console.error('attach success', error);
      return;
    }

    const roomObj = list && list?.find((x) => x.description === roomName);

    if (roomObj) {
      this.roomId = roomObj.room;
      if (roomObj['num_participants'] >= roomObj['max_publishers']) {
        return alert('в комнате уже максимум участников');
      }
    } else {
      const msgCreateRoom = {
        request: 'create',
        permanent: false,
        description: roomName,
        bitrate: this.bitrateMaxValue,
        bitrate_cap: this.bitrate_cap,
        publishers: 2,
        data: true,
      };
      let createRoomResponse;

      try {
        createRoomResponse = await this.sendMessage({ message: msgCreateRoom });
      } catch (error) {
        console.error('attach success', error);
        return;
      }

      console.log('createRoomResponse', createRoomResponse);
      this.roomId = createRoomResponse?.room;
    }

    console.warn('this.roomId ', this.roomId);

    const register = {
      request: 'join',
      room: this.roomId,
      ptype: 'publisher',
      display: userName,
      data: true,
    };
    this.pluginHandle.send({ message: register });
  };

  joinRoomId = async (roomId) => {
    this.roomId = roomId;

    const register = {
      request: 'join',
      room: this.roomId,
      ptype: 'publisher',
      display: this.opaqueId,
      data: true,
    };
    this.pluginHandle.send({ message: register });
  };

  getRoomId = async (roomName) => {
    const msgList = { request: 'list' };

    let list;
    try {
      const response = await this.sendMessage({ message: msgList });
      list = response?.list;
    } catch (error) {
      console.error('attach success', error);
      return;
    }

    const roomObj = list && list?.find((x) => x.description === roomName);

    return roomObj?.room;
  };

  getRoomInfo = async (roomId) => {
    try {
      const response = await this.sendMessage({
        message: { request: 'listparticipants', room: roomId },
      });

      return response?.participants;
    } catch (error) {
      console.error('attach success', error);
      return;
    }
  };

  leaveRoom = () => {
    this.pluginHandle?.detach();
    this.pluginHandle = null;
    this.remoteHandles.forEach((x) => {
      x.detach();
      this.removeClient(x.rfid);
    });
    this.remoteHandles = [];
    this.setJoined?.(false);
    this.attachVideoRoomPlugin(this.opaqueId);
  };

  destroyRoom = () => {
    this.pluginHandle?.detach();
    this.pluginHandle = null;
    this.remoteHandles.forEach((x) => {
      x.detach();
      this.removeClient(x.rfid);
    });
    this.remoteHandles = [];
    this.setJoined?.(false);
    // this.attachVideoRoomPlugin(this.opaqueId);

    if (this.isMuteVideo === false) {
      this.toggleVideo();
    }

    if (this.isMuteAudio === false) {
      this.toggleAudio();
    }

    this.setDestroyed?.(true);
  };

  callDestroyRoom = () => {
    this.sendMessage({
      message: {
        request: 'destroy',
        room: this.roomId,
      },
    });
  };

  startPublishing = () => {
    Janus.log('Starting publishing...');
    this.pluginHandle.createOffer({
      media: { removeVideo: true, removeAudio: true, data: true },
      stream: this.mediaStream,
      success: (jsep) => {
        Janus.log('Got publisher SDP!', jsep);

        this.pluginHandle.send({
          message: { request: 'configure', audio: true, video: true },
          jsep,
          success: (result) => {
            Janus.log('Successfully sent message: ', result);
          },
          error: (cause) => {
            Janus.error('Error sending publish request: ', cause);
          },
        });
      },
      error: (error) => {
        Janus.log('Error creating offer: ', error);
      },
    });

    this.setJoined?.(true);
  };

  remoteFeedHandler = (list) => {
    list.forEach((x) => this.newRemoteFeed(x.id, x.display));
  };

  newRemoteFeed = (id, display) => {
    let remoteFeed;

    this.janus.attach({
      plugin: 'janus.plugin.videoroom',
      opaqueId: this.opaqueId,
      success: (pluginHandle) => {
        console.warn('new remote feed', pluginHandle);
        remoteFeed = pluginHandle;

        remoteFeed.rfid = id;
        remoteFeed.rfdisplay = display;

        // const doobleHandle = this.remoteHandles.find(x => x.rfid === id);
        // const newRemoteFeed = doobleHandle ? this.remoteHandles.filter(x => x !== doobleHandle) : this.remoteHandles;
        // this.remoteHandles = [...newRemoteFeed, remoteFeed]
        this.remoteHandles.push(remoteFeed);

        // doobleHandle?.detach({ success: () => { console.warn("doobleHandle detached") } })

        // console.warn("doobleHandle need detached", doobleHandle)

        console.warn('this.remoteHandles', this.remoteHandles);

        pluginHandle.videoCodec = 'vp8';

        const subscribe = {
          request: 'join',
          room: this.roomId,
          ptype: 'subscriber',
          feed: id,
          private_id: this.mypvtid,
        };

        pluginHandle.send({ message: subscribe });
      },
      onmessage: (msg, jsep) => {
        const event = msg.videoroom;
        if (event) {
          if (event === 'attached') {
            //
          }
        }

        if (jsep) {
          remoteFeed.createAnswer({
            jsep,
            media: { audioSend: false, videoSend: false, data: true },
            success: (jsep) => {
              const message = { request: 'start', room: this.roomId };
              remoteFeed.send({ message, jsep });
              remoteFeed.data({
                text: `-------------- ${this.opaqueId}`,
                error: (reason) => {},
                success: () => {
                  console.warn(this.opaqueId);
                },
              });
            },
            error(error) {},
          });
        }
      },
      onremotestream: (stream) => {
        const audioTrack = stream.getAudioTracks()?.[0];
        const videoTrack = stream.getVideoTracks()?.[0];
        console.log('onremotestream', stream, audioTrack, videoTrack);

        console.warn('this.addClient');

        let videoElement = null;
        let changeAudioOutput = null;

        this.addClient?.({
          handleId: remoteFeed.rfid,
          display: remoteFeed.rfdisplay,
          attachVideo: (videoRef) => {
            console.log('attachVideo');
            videoElement = videoRef;
            try {
              const newStream2 = new MediaStream();
              const audioTrack = stream.getAudioTracks()?.[0];
              const videoTrack = stream.getVideoTracks()?.[0];
              console.warn('videoTrack', videoTrack);

              if (audioTrack) {
                newStream2.addTrack(audioTrack);
              }

              setTimeout(() => {
                if (videoTrack) {
                  // videoRef.removeAttribute('controls');
                  // videoRef.pause();
                  newStream2.addTrack(videoTrack);
                  // videoRef.play();
                  // videoRef.setAttribute('controls', '');
                }
              }, 1000);

              // videoRef.pause();
              videoRef.srcObject = new MediaStream();
              videoRef.src = null;
              // videoRef.load();
              // videoRef.play();

              if (audioOutput.deviceId) {
                setTimeout(() => {
                  videoRef.setSinkId(audioOutput.deviceId);
                }, 0);
              }

              changeAudioOutput = (event) => {
                videoRef.setSinkId(event.detail.deviceId);
              };
              document.addEventListener('changeAudioOutput', changeAudioOutput);

              Janus.attachMediaStream(videoRef, newStream2);
            } catch (error) {
              console.warn(error);
            }
          },
          remove: () => {
            if (videoElement) {
              document.removeEventListener('changeAudioOutput', changeAudioOutput);
            }
          },
        });
      },
      ondataopen: (data) => {
        Janus.log('newRemoteFeed The DataChannel is available!');
      },
      ondata: (data) => {
        Janus.debug('newRemoteFeed We got data from the DataChannel!', data);

        data = JSON.parse(data);
        this.dataHandles.forEach((x) => x(data));
      },
      error: (error) => {
        console.warn('--------- Error attaching plugin', error);
      },
      consentDialog: (params) => {
        console.warn('--------- consentDialog', params);
      },
      connectionState: (params) => {
        console.warn('--------- connectionState', params);
      },
      mediaState: (params) => {
        console.warn('--------- mediaState', params);
      },
      slowLink: (params) => {
        console.warn('--------- slowLink', params);
      },
      oncleanup: (params) => {
        console.warn('--------- oncleanup', params);
      },
      detached: (params) => {
        console.warn('--------- detached', params);
      },
    });
  };

  sendMessage = (messageContent) =>
    new Promise((resolve, reject) => {
      this.pluginHandle?.send({
        ...messageContent,
        success: (response) => {
          resolve(response);
        },
        error: (reason) => {
          reject(reason);
        },
      });
    });

  attachVideoPlayer = (videoJsPlayer) => {
    console.warn('attachVideoPlayer ', videoJsPlayer);

    this.dataHandles.push(videoJsPlayer.onChangedStatus);

    const handler = (data) => {
      console.warn('changedStatus', data);
      this.pluginHandle.data({ text: JSON.stringify(data.detail) });
    };
    videoJsPlayer.addChangedStatusListener(handler);
  };

  detachVideoPlayer = (videoJsPlayer) => {
    console.warn('detachVideoPlayer ', videoJsPlayer);
    this.dataHandles = this.dataHandles.filter((x) => x !== videoJsPlayer.onChangedStatus);
    videoJsPlayer.deleteChangeStatusHandler();
  };
}
